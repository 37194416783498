<template>
  <head>
    <title>定期点検</title>
  </head>
  <aheader/>
  <!-- <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1.0"> -->
  <div class="container is-fluid">
    <div class="section">
      <p class="title">
        なぜ、定期点検をするのか？
      </p>
    </div>
    <div class="block">
      <!-- <img height="398" width="570" style="float: bottom;" src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/maintenance%2FTenken_top.png?alt=media&token=ede1af51-8a12-4086-af31-41678350c9e1"> -->
      <img height="398" width="570" style="float: bottom;" src= "@/assets/Tenken_top.png">
    </div>
    <div class="block">
      <div class="content is-normal">
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <h4 style="line-height : 220%; text-align: left;">長年、お気に入りのふとんを愛用するためには定期的な点検と打ち直しが必要です。ふとんは打ちなおすと、ふわふわなふとんになるだけではなく、あたたかさ(保温性)や汗を吸う力(吸水性)が良くなります。もちろん、ふとんの中に閉じ込められたダニやホコリも綺麗に取り除きます。いつまでもふかふかできもちの良いおふとんで、極上の睡眠を実現していただくためにおぐら屋ではふとんの定期点検を行っています。</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="section">
        <p class="title">
          <u>Before</u>
        </p>
        <!-- <img height="300" width="500" style="float: bottom;" src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/maintenance%2Ffuton_before.jpg?alt=media&token=a43066ba-cdbc-47bf-9531-5a2a91ab9f1c"> -->
        <img height="300" width="500" style="float: bottom;" src="@/assets/futon_before.jpg">
        <br/>
        <div class="arrow1"></div>
        <br/>
        <p class="title">
          <u>After</u>
        </p>
        <!-- <img height="300" width="500" style="float: bottom;" src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/maintenance%2Ffuton_after.jpg?alt=media&token=7b285130-9787-4076-a1ae-ff8192b33d66"> -->
        <img height="300" width="500" style="float: bottom;" src="@/assets/futon_after.jpg">
      </div>
    </div>
  </div>



    <div class="block">
      <div class="has-background-info-light" style="padding-bottom:30px;">
        <div class="section">
          <p class="title">
            定期点検の手順
          </p>
        </div>
        <div class="content is-normal">
          <div class="block" v-for="item, index in itemList" :key="index">
            <div class="columns">
              <div class="column is-8 is-offset-2">
                <h3 class="kyoutyou" style="margin-bottom:30px;">{{item.name}}</h3>
                <div class="columns">
                  <div style="margin:auto;">
                    <div class="container is-fluid">
                      <img height="500" width="600" style="margin-bottom:10px; float: bottom;" :src="item.icon">
                    </div>
                  </div>
                </div>
                <div class="column is-narrow">
                  <div class="container is-fluid">
                    <h4 class="has-text-left-touch" style="line-height : 220%;" :class="addTextLeft">{{item.kind}}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block">
          <button class="button is-medium has-background-danger-dark has-text-white-ter" @click="pushPage('application')">定期点検のご依頼はこちら</button>
        </div>
      </div>
    </div>

  <afooter/>
</template>

<style>
#measure{
  line-height: 2;
  text-align:left
}
#tejun{
  background-color: has-background-warning-light;
}
.kyoutyou{
  display:inline-block;
  padding:5px 0;
  border-top:5px double black;
  border-bottom:5px double black;
}
.arrow1{
  margin: 0 auto;
  text-align: center;
  width: 30px;
  height: 30px;
  border: 5px solid;
  border-color:  transparent transparent #565656 #565656;
  transform: rotate(-45deg);
}

</style>
<script>
  export default {
    data() {
      return {
        itemList: [
          // {name: "１.メンテナンスパックのご購入", icon: "https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/maintenance%2FTenken_1.jpg?alt=media&token=934ecf39-f58e-4f09-a87b-7d8d62622067", kind: "メンテナンス時期にあわせて弊社から布団送付専用BOXをお届けいたします。"},
          // {name: "２.宅配便にて返送", icon: "https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/maintenance%2FTenken_2.jpg?alt=media&token=47fc84d6-ab0c-4085-a6c6-30208d103a94", kind: "お客様のお布団を専用BOXに入れて、期間内にご発送ください。お預かり次第、メンテナンスをいたします。"},
          // {name: "３.メンテナンス済のお布団をお届け", icon: "https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/maintenance%2Fjosei_mezame.jpg?alt=media&token=184a67c4-7f65-443d-882c-eabfd509d521", kind: "生まれ変わったお布団で、心地の良い眠りをご体感ください。"},
          {name: "１.メンテナンスパックのご購入", icon: require("@/assets/Tenken_1.jpg"), kind: "メンテナンス時期にあわせて弊社から布団送付専用BOXをお届けいたします。"},
          {name: "２.宅配便にて返送", icon: require("@/assets/Tenken_2.jpg"), kind: "お客様のお布団を専用BOXに入れて、期間内にご発送ください。お預かり次第、メンテナンスをいたします。"},
          {name: "３.メンテナンス済のお布団をお届け", icon: require("@/assets/josei_mezame.jpg"), kind: "生まれ変わったお布団で、心地の良い眠りをご体感ください。"},
        ],
        modalClass: ""
      }
    },
    mounted() {
      this.scrollTop();
    },
    computed: {
      addTextLeft() {
        let screenSize = document.body.offsetWidth
        // console.log(screenSize)
        if (screenSize <= 768) {
          // console.log(screenSize)
          return "has-text-left"
        } else {
          return null
        }
      }
    },
    methods: {
      pushPage(num) {
        this.$router.push(num);
      },
      scrollTop() {
        this.$nextTick(() => {
          window.scrollTo(0, 0);
        })
      },
    }
  }
</script>
